
import { mapGetters, mapState } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Homepage',
  components: {
    LinePopup: defineAsyncComponent(() => import('~/components/Popup/Line.vue')),
    NeedHelpPopup: defineAsyncComponent(() => import('~/components/UI/need-help-popup.vue')),
    Banner: defineAsyncComponent(() => import('~/components/Banner/Banner.vue')),
    SampleProduct: defineAsyncComponent(() => import('~/components/SampleProduct/SampleProduct.vue')),
    SmallBanner: defineAsyncComponent(() => import('~/components/Banner/SmallBanner.vue')),
    Product: defineAsyncComponent(() => import('~/components/Product/Product.vue')),
    ProductCategory: defineAsyncComponent(() => import('~/components/Product/ProductCategory.vue')),
    CustomerFeedback: defineAsyncComponent(() => import('~/components/Customer/CustomerFeedback.vue')),
    Promotion: defineAsyncComponent(() => import('~/components/Promotion/Promotion.vue'))
  },
  async asyncData({ $axios, $replaceSEO }) {
    try {
        // เรียกข้อมูลทั้งหมดพร้อมกัน
        const [seo, promoCodeResponse, bannersResponse, sampleProductCategories] = await Promise.all([
            $axios.get('sitemaps/home'),
            $axios.get('get-last-promocode-active'),
            $axios.get('banners'),
            $axios.get('sample_product_categories')
        ]);

        console.log('sammmmm', sampleProductCategories.data)
        // คืนค่าข้อมูลที่ต้องการ
        return {
            promoCodePopup: promoCodeResponse ? promoCodeResponse.data : null,
            seo: $replaceSEO(seo.data.data, null, null, null, null, null),
            banners: bannersResponse ? bannersResponse.data : [],
            homepage_features: bannersResponse ? bannersResponse.data : [],
            sampleProductCategories: sampleProductCategories.data ?sampleProductCategories.data : [],
            // banners: bannersResponse ? bannersResponse.data.banner_slide : [],
            // homepage_features: bannersResponse ? bannersResponse.data.homepage_feature : [],
            // sampleProductCategories: sampleProductCategories.data.data ?sampleProductCategories.data.data : [],
        };
    } catch (error) {
        // จัดการข้อผิดพลาดที่เกิดขึ้น
        console.error('Error fetching data:', error);
        return {
            promoCodePopup: null,
            seo: null,
            banners: [],
            homepage_features: [],
            sampleProductCategories: []
        };
    }
  },
//   async asyncData ({ $axios, $replaceSEO }) {
//     const [seo, lastPromoCode, banners] = await Promise.all([
//         $axios.get('sitemaps/home'),
//         $axios.get('get-last-promocode-active'),
//         $axios.get('banners')
//     ]);

//     const promoCodePopup = lastPromoCode ? lastPromoCode.data : null;

//     return {
//         promoCodePopup,
//         seo: $replaceSEO(seo.data.data, null, null, null, null, null),
//         banners: banners.data.banner_slide,
//         homepage_features: banners.data.homepage_feature
//     };
// },
  data () {
    return {
      waypointLoaded: [],
      slide: 0,
      tabLeft: false,
      tabRight: true,
      tabItem: 'all',
      videoUrlBanner: '',
      banners: [],
      homepage_features: [],
      sampleProductCategories: [],
      productGroupCategories: [],
      patternsSort: [],
      patternsCat: [],
      webmIsSupport: true,
      cover_video: [true, true, true],
      loadMore: false,
      articles: [],
      articles_promotion: [],
      isMobile: false,
    }
  },
  computed: {
    ...mapState(['userData']),
    ...mapGetters(['isAuthenticated']),
  }
}
